<template>
  <div>
    <div class="flex">
      <div class="w-5/6">
        <h2 class="pb-3">Dashboard</h2>
        <div class="flex w-full" style="padding:20px;" v-if="currentUser.logo != ''"><img
            :src="url + '/user_profile/' + currentUser.logo" height="100" width="100" /><span
            style="padding:20px;">Welcome to
            {{ currentUser.company_name }}</span></div>
      </div>
      <div class="w-1/6">
        <router-link :to="{ name: 'users' }" class="el-button float-right el-button--success"
          v-if="parentGc == 'null' || parentGc == ''">Users</router-link>
      </div>
    </div>
    <div class="flex h-full" style="box-shadow: 7px 15px 29px 0 rgba(206, 197, 197, 0.27)">
      <div class="w-full bg-white p-8 dashHeader">
        <div class="flex h-full">
          <div class="w-1/6 h-full px-3">
            <router-link :to="{ name: 'projects' }">
              <div class="main-folder bg-contain h-full">
                <div class="folder-count">
                  {{ counts.project_count }}
                </div>
                <div class="folder-text">
                  <translate source="Projects" />
                </div>
              </div>
            </router-link>
          </div>
          <div class="w-1/6 h-full px-3">
            <div class="inactive-folder bg-contain h-full">
              <router-link :to="{ name: 'vendors' }">
                <div class="folder-count">
                  {{ counts.vendor_count }}
                </div>
                <div class="folder-text">
                  <translate source="Vendors" />
                </div>
              </router-link>
              <br />
              <a class="export-link" target="_blank" :href="url + '/app/export/vendor-policies'">(export)</a>
            </div>
          </div>
          <div class="w-1/6 h-full px-3">
            <router-link :to="{ name: 'policies' }">
              <div class="inactive-folder bg-contain h-full">
                <div class="folder-count">
                  {{ counts.policy_count }}
                </div>
                <div class="folder-text">
                  <translate source="Policies" />
                </div>
		<br/>
		<div class="folder-text">
		Compliant Policies {{ counts.compliant }}%
		</div>
              </div>
            </router-link>
          </div>
          <div class="w-1/6 h-full px-3">
            <router-link :to="{ name: 'contracts' }">
              <div class="inactive-folder bg-contain h-full">
                <div class="folder-count">{{ counts.contract_count }}</div>
                <div class="folder-text">
                  <translate source="Contracts" />
                </div>
              </div>
            </router-link>
          </div>
         <div class="w-1/6 h-full px-3">
            <router-link :to="{ name: 'reports' }">
              <div class="inactive-folder bg-contain h-full">
                <div class="folder-count">1</div>
                <div class="folder-text">
                  <translate source="Reports" />
                </div>
              </div>
            </router-link>
          </div>
          <div class="w-1/6 h-full px-3">
            <router-link :to="{ name: 'bulk-notify' }">
              <div class="inactive-folder bg-contain h-full">
                <div class="folder-count">1</div>
                <div class="folder-text">
                  <translate source="Bulk Notification" />
                </div>
              </div>
            </router-link>
          </div>
          <el-dialog title='New User' :visible.sync="showNewVendor" width="70%">
            <div class='flex'>
              <div class='w-1/2'>
                <label>
                  User Name
                </label>
                <CoiInput v-model='newUser.name'></CoiInput>
              </div>
              <div class='w-1/2'>
                <label>
                  Contact Email
                </label>
                <CoiInput v-model='newUser.email'></CoiInput>
              </div>
              <!-- <div class = 'w-1/2'>
                    <label>
                        Contact Name
                    </label>
                    <CoiInput v-model = 'newVendor.contactName'></CoiInput>
                </div> -->
            </div>
            <!-- <div class = 'flex'>
                <div class = 'w-1/2'>
                    <label>
                        Contact Email
                    </label>
                    <CoiInput v-model = 'newVendor.contactEmail'></CoiInput>
                </div>
            </div> -->
            <span slot="footer" class="dialog-footer">
              <el-button @click="showNewVendor = false">Cancel</el-button>
              <el-button type="primary" @click="saveNewUser">Save</el-button>
            </span>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CoiInput from "../../common/CoiInput";
import Validator from "../../common/Validator";
import { USER_INVITE } from "../../../store/actions/user";
import { AUTH_LOAD_USER } from "../../../store/actions/auth";
export default {
  name: "DashboardHeader",
  components: {
    CoiInput
  },
  data() {
    return {
      url: process.env.VUE_APP_ROOT,
      showNewVendor: false,
      parentGc: '',
      newUser: {
        name: '',
        email: ''
      },
      currentUser: {}
    }
  },
  methods: {
    checkVendorName() {
    },
    saveNewUser() {
      if (!Validator([
        { field: this.newUser.name, name: 'Name' },
        { field: this.newUser.email, name: 'Email' },
      ])) {
        return;
      }
      this.$store.dispatch(USER_INVITE, this.newUser).then(() => {
        this.showNewVendor = false;
        this.$notify({
          title: 'success',
          message: 'User invitation send successfully',
          type: 'success'
        });
      }).catch();
    },
    _getCounts() {
      this.$store
        .dispatch("UTILITY_INIT_DASHBOARD_COUNTS")
        .then(() => { })
        .catch();
    },
  },
  computed: {
    counts: function () {
      return this.$store.getters.getDashboardCounts;
    },
  },
  mounted() {
    this.parentGc = localStorage.getItem('parent-gc');
    this._getCounts();
    this.$store.dispatch(AUTH_LOAD_USER).then((user) => {
      this.currentUser = user;
    }).catch();
  },
};
</script>

<style scoped>
.export-link {
  color: #8bb3e4;
  text-align: center;
  display: block;
  margin-top: -15px;
}

h2 {
  color: #255897;
  font-family: "Open Sans", sans-serif;
  font-size: 25px;
  font-weight: bold;
  line-height: 34px;
  text-transform: uppercase;
}

.dashHeader {
  min-height: 238px;
}

.folder-text {
  margin-top: -10px;
  font-family: Muli, sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.1px;
  text-align: center;
}

.folder-count {
  padding-top: 34px;
  font-family: "Open Sans", sans-serif;
  font-size: 44px;
  font-weight: 600;
  text-align: center;
  text-shadow: 0 2px 4px rgba(46, 46, 46, 0.5);
}

.main-folder {
  background-image: url("~@/assets/images/active-folder.svg");
  background-repeat: no-repeat;
}

.main-folder .folder-count {
  color: #ffffff;
}

.main-folder .folder-text {
  color: #b2bfff;
}

.inactive-folder {
  background-image: url("~@/assets/images/inactive-folder.svg");
  background-repeat: no-repeat;
}

.inactive-folder .folder-count {
  color: #255897;
}

.inactive-folder .folder-text {
  color: #8bb3e4;
}

/*xs*/
@media (max-width: 640px) {
  .folder-count {
    font-size: 16px;
    padding-top: 10px;
  }

  .folder-text {
    font-size: 14px;
  }

  .dashHeader {
    min-height: 80px;
  }
}

/*sm*/
@media (min-width: 640px) {
  .folder-count {
    font-size: 16px;
    padding-top: 10px;
  }

  .folder-text {
    font-size: 14px;
  }

  .dashHeader {
    min-height: 120px;
  }
}

/*md*/
@media (min-width: 768px) {
  .folder-count {
    font-size: 20px;
    padding-top: 20px;
  }

  .dashHeader {
    min-height: 170px;
  }
}

/*lg*/
@media (min-width: 1024px) {
  .folder-count {
    font-size: 38px;
    padding-top: 28px;
  }

  .dashHeader {
    min-height: 200px;
  }
}

/*xl*/
@media (min-width: 1280px) {
  .folder-count {
    font-size: 44px;
    padding-top: 34px;
  }

  .dashHeader {
    min-height: 238px;
  }
}

@media (min-width: 1699px) {
  .px-3 {
    padding-left: 5.75rem;
    padding-right: 0.75rem;
  }
}

@media (min-width: 1999px) {
  .px-3 {
    padding-left: 6.75rem;
    padding-right: 0.75rem;
  }
}

@media (min-width: 2199px) {
  .px-3 {
    padding-left: 8.75rem;
    padding-right: 0.75rem;
  }
}

@media (min-width: 2299px) {
  .px-3 {
    padding-left: 9.75rem;
    padding-right: 0.75rem;
  }
}

@media (min-width: 2399px) {
  .px-3 {
    padding-left: 11.75rem;
    padding-right: 0.75rem;
  }
}

@media (min-width: 2499px) {
  .px-3 {
    padding-left: 13.75rem;
    padding-right: 0.75rem;
  }
}
</style>

