<template>
    <div class = 'container mx-auto'>
        <Header />
        <div class = 'container main-container min-h-screen p-8'>
            <!-- <DashboardHeader /> -->
            <router-view/>
        </div>
    </div>
</template>

<script>
    import Header from "../components/dashboard/partial/Header";
    // import DashboardHeader from "../components/dashboard/partial/DashboardHeader";
    import {AUTH_SET_TOKEN} from "../store/actions/auth";
    import {LOAD_TRANSLATION} from "../store/actions/translate";
    export default {
        name: 'Login',
        components: {
            Header
        },
        mounted() {
            this.$store.dispatch(AUTH_SET_TOKEN, this.$route.query);
            this.$store.dispatch(LOAD_TRANSLATION).then(() => {}).catch();
        }
    }
</script>

<style scoped>
    .main-container{
        background-color: #FAFAFA;
        min-height: 1000px;
    }
    .container {
        max-width: 100% !important;
    }
</style>